import React from 'react';
import Layout from '../layouts/Layout';

import circles from '../images/circles-white.png';
import arrowRight from '../images/arrow-right-w.svg';

import ContactUs from '../components/ContactUs/ContactUs';
import { getTranslatedString } from '../i18n/utils';

const JobDescription = ({ pageContext }: { pageContext: Job }) => {
	const isFrench = pageContext.applicationFormUrl.trim().endsWith('/fr_CA');

	return (
		<Layout title={'Transdev Careers'} desc={'Transdev Careers'}>
			<section className="relative bg-pri text-white">
				<div className="absolute bottom-4 right-0 w-[625px]">
					<img
						src={circles}
						className="object-cover opacity-40"
						alt="Cirles"
						width={625}
						height={195}
					/>
				</div>
				<div className="container relative z-10 mx-auto flex w-full flex-col gap-10 px-5 py-12">
					<div className="flex flex-col gap-2">
						<a
							className={
								'mb-6 inline-flex items-center gap-3 text-xl font-medium text-white hover:opacity-90'
							}
							href={isFrench ? '/recherche/' : '/search/'}
						>
							<img src={arrowRight} className={'rotate-180'} />
							{isFrench ? 'Retour' : 'Back'}
						</a>
						<h3 className="text-[53px] font-bold uppercase max-sm:text-4xl">
							{isFrench ? "OFFRES D'EMPLOI" : 'JOB OFFER'}
						</h3>
						<p>
							{isFrench ? 'AVEC ' : 'WITH '}
							<span
								className="font-medium uppercase"
								dangerouslySetInnerHTML={{
									__html: pageContext.division
								}}
							></span>
						</p>
					</div>
				</div>
			</section>

			<section>
				<div
					className={
						'container mx-auto my-20 flex flex-col gap-10 px-5'
					}
				>
					<div className={'flex w-full flex-col gap-6'}>
						<p className="font-medium uppercase text-pri">
							{isFrench
								? getTranslatedString(pageContext.category)
								: pageContext.category}
							{' · '}
							{pageContext.division}
							{', '}
							{pageContext.city}
						</p>
						<h1 className="text-7xl font-semibold max-md:text-5xl">
							{pageContext.title}
						</h1>
						<a
							href={pageContext.url}
							className="ApplyButton ApplyButtonTop w-full max-w-[220px] bg-pri px-5 py-3 text-center text-base font-medium text-white transition-all hover:bg-[#940021]"
						>
							{isFrench ? 'Postuler' : 'Apply'}
						</a>
					</div>
					<div className={'flex w-full flex-col'}>
						<p
							dangerouslySetInnerHTML={{
								__html: pageContext.description
							}}
							className="flex flex-col gap-4"
						/>
					</div>
					<a
						href={pageContext.url}
						className="ApplyButton ApplyButtonBottom w-full max-w-[220px] bg-pri px-5 py-3 text-center text-base font-medium text-white transition-all hover:bg-[#940021]"
					>
						{isFrench ? 'Postuler' : 'Apply'}
					</a>
					<div className="flex flex-col gap-6 rounded-2xl bg-[#F8F8F8] px-16 py-6 max-sm:px-4">
						<div className="max-w-1/2 flex gap-6 max-sm:gap-2">
							<p className="w-1/2 text-xl font-bold max-sm:text-lg">
								{isFrench ? 'Département' : 'Department'}
							</p>
							<p className="w-1/2 text-xl font-bold text-pri max-sm:text-lg">
								{isFrench
									? getTranslatedString(pageContext.category)
									: pageContext.category}
							</p>
						</div>
						<div className="flex gap-6 max-sm:gap-2">
							<p className="w-1/2 text-xl font-bold max-sm:text-lg">
								{isFrench ? 'Lieux' : 'Location'}
							</p>

							<p className="w-1/2 text-xl font-bold max-sm:text-lg">
								{pageContext.city}
							</p>
						</div>
					</div>
				</div>
			</section>
			<ContactUs />
		</Layout>
	);
};

export default JobDescription;
